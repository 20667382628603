import React from 'react';
import '../../App.css';
import ChatbotPreview from "../../ChatbotPreview";

const CustomerSupportBot = () => {
    return <ChatbotPreview chatbotName={"Customer Support Assistant"}
                           companyLogo={"logos/customer_support_logo.png"}
                           projectID={"663918ca5e1cb054e29af1ee"}/>
};

export default CustomerSupportBot;
