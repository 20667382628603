import React from 'react';
import '../App.css';
import ChatbotPreview from "../ChatbotPreview";

const PrivateSchoolBot = () => {
    return <ChatbotPreview chatbotName={"Private School Bot"} companyLogo={"logos/private_school_logo.png"}
                           projectID={"665a24081b6dc5ad8a6bab5a"}/>
};

export default PrivateSchoolBot;
