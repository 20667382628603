import React from 'react';
import '../App.css';
import ChatbotPreview from "../ChatbotPreview";

const Mayalanya = () => {
    return <ChatbotPreview chatbotName={"Mayalanya Assistant"} companyLogo={"logos/mayalanya_logo.png"}
                           projectID={"65c917af8c45dbdd731cc4b4"}/>
};

export default Mayalanya;
