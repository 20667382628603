import React from 'react';
import '../../App.css';
import ChatbotPreview from "../../ChatbotPreview";

const LeadGenerationBot = () => {
    return <ChatbotPreview chatbotName={"Lead Generation Assistant"}
                           companyLogo={"logos/customer_support_logo.png"}
                           projectID={"663953105e1cb054e29af697"}/>
};

export default LeadGenerationBot;
