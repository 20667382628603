import React from 'react';
import '../App.css';
import ChatbotPreview from "../ChatbotPreview";

const UpworkProposalAssistant = () => {
    return <ChatbotPreview chatbotName={"Upwork Proposal Writer Assistant"} companyLogo={"logos/upwork_proposal_assistant.png"}
                           projectID={"663d07176b9589c38bfe602f"}/>
};

export default UpworkProposalAssistant;
