import React from 'react';
import '../../App.css';
import ChatbotPreview from "../../ChatbotPreview";

const ECommerceProductRecommendationBot = () => {
    return <ChatbotPreview chatbotName={"E-Commerce Product Recommendation"}
                           companyLogo={"logos/customer_support_logo.png"}
                           projectID={"6638ff16570bf86dee3eba5f"}/>
};

export default ECommerceProductRecommendationBot;
