import React from 'react';
import '../App.css';
import ChatbotPreview from "../ChatbotPreview";

const SunriseHotelBot = () => {
    return <ChatbotPreview chatbotName={"Sunrise Hotel"} companyLogo={"logos/sunrise_hotel_logo.png"}
                           projectID={"665e1aac41ccc8b5118914b2"}/>
};

export default SunriseHotelBot;
