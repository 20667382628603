import React from 'react';
import '../App.css';
import ChatbotPreview from "../ChatbotPreview";

const CustomerSupportAssistant = () => {
    return <ChatbotPreview chatbotName={"Ecommerce Customer Support Assistant"} companyLogo={"logos/customer_support_logo.png"}
                           projectID={"66377b809233d58f51e06770"}/>
};

export default CustomerSupportAssistant;
