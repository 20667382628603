import React from 'react';
import '../App.css';
import ChatbotPreview from "../ChatbotPreview";

const SalmaChildDietBot = () => {
    return <ChatbotPreview chatbotName={"Child Nutritionist"} companyLogo={"logos/salma_child_diet_bot_logo.png"}
                           projectID={"661f8552fe12ace18dabd6ec"}/>
};

export default SalmaChildDietBot;
