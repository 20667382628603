import React from 'react';
import '../App.css';
import ChatbotPreview from "../ChatbotPreview";

const SalmaDietBotAr = () => {
    return <ChatbotPreview chatbotName={"Diet Diversity Assistant"} companyLogo={"logos/salma_diet_bot_logo.png"}
                           projectID={"66151c4488ad5ce42d5ab6d2"}/>
};

export default SalmaDietBotAr;
