import React, {useEffect} from "react";

const FacebookLoginButton = () => {
    useEffect(() => {
        // Initialize Facebook SDK
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: "1370974893615088",
                cookie: true, // enable cookies
                xfbml: true,
                version: "v19.0"
            });
        };

        // Asynchronously load the Facebook SDK
        const loadFacebookSDK = (d, s, id) => {
            let js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }

        loadFacebookSDK(document, "script", "facebook-jssdk");

        const sessionInfoListener = (event) => {
            if (event.origin !== "https://www.facebook.com") return;
            try {
                const data = JSON.parse(event.data);
                if (data.type === "WA_EMBEDDED_SIGNUP") {
                    // if user finishes the Embedded Signup flow
                    if (data.event === "FINISH") {
                        const {phone_number_id, waba_id} = data.data;
                        console.log("Success!")
                        console.log("Phone Number ID: " + phone_number_id)
                        console.log("WhatsApp Business Account ID: " + waba_id)
                    }
                    // if user cancels the Embedded Signup flow
                    else {
                        const {current_step} = data.data;
                        console.log("Cancelled, current_step: " + current_step)
                    }
                }
            } catch {
                // Don’t parse info that’s not a JSON
                console.log("Non JSON Response", event.data);
            }
        };

        window.addEventListener("message", sessionInfoListener);
    }, []);

    // Function to handle login
    const launchWhatsAppSignup = () => {
        window.FB.login(function (response) {
                if (response.authResponse) {
                    const code = response.authResponse.code;
                    // The returned code must be transmitted to your backend,
                    // which will perform a server-to-server call from there to our servers for an access token
                } else {
                    console.log("User cancelled login or did not fully authorize.");
                }
            }, {
                config_id: "451426267371515", // configuration ID goes here
                response_type: "code",    // must be set to "code" for System User access token
                override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
                extras: {
                    setup: {
                        // business: {
                        //     name: "Acme Inc.",
                        //     email: "johndoe@acme.com",
                        //     phone: {
                        //         code: 1,
                        //         number: "6505551234"
                        //     },
                        //     website: "https://www.acme.com",
                        //     address: {
                        //         streetAddress1: "1 Acme Way",
                        //         city: "Acme Town",
                        //         state: "CA",
                        //         zipPostal: "94000",
                        //         country: "US"
                        //     },
                        //     timezone: "UTC-08:00"
                        // },
                        // phone: {
                        //     displayName: "Acme Inc",
                        //     category: "ENTERTAIN",
                        //     description: "Acme Inc. is a leading entertainment company.",
                        // }
                    }
                }
            }
        );
    };

    return (
        <button
            onClick={launchWhatsAppSignup}
            style={{
                backgroundColor: "#1877f2",
                border: 0,
                borderRadius: 4,
                color: "#fff",
                cursor: "pointer",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontSize: 16,
                fontWeight: "bold",
                height: 40,
                padding: "0 24px"
            }}
        >
            Login with Facebook
        </button>
    );
}

export default FacebookLoginButton;
