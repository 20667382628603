import React from 'react';
import '../App.css';
import ChatbotPreview from "../ChatbotPreview";

const SITE_URL = "https://preview.wesolve.ai";

const RastAssistant = () => {
    return <ChatbotPreview chatbotName={"Rast Assistant"} companyLogo={"logos/rast_logo.png"}
                           projectID={"661719290bdf10c13ba47e75"}
                           stylesheet={SITE_URL + "/rast-beer-garden-styling.css"}/>
};

export default RastAssistant;
