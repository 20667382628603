import React from 'react';
import '../App.css';
import ChatbotPreview from "../ChatbotPreview";

const FoodOrderingAssistant = () => {
    return <ChatbotPreview chatbotName={"Oliveto Pizzeria"} companyLogo={"logos/food_ordering_assistant.png"}
                           projectID={"6690ef5e7a37551a655793a2"}/>
};

export default FoodOrderingAssistant;
