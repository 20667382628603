import {useEffect} from 'react';
import { VideoExtension,
    TimerExtension,
    FormExtension,
    MapExtension,
    FileUploadExtension,
    KBUploadExtension,
    createDateExtension,
    ConfettiExtension,
    FeedbackExtension } from './voiceflow_extensions.js';

export function ShowVoiceflowChatbot(projectId, stylesheet = "") {
    useEffect(() => {
        const voiceflow = document.createElement("script");
        voiceflow.src = "https://cdn.voiceflow.com/widget/bundle.mjs";
        voiceflow.type = "module";
        voiceflow.onload = function () {
            let assistantConfig = {
                verify: {projectID: projectId},
                url: "https://general-runtime.voiceflow.com",
                versionID: "production",
                render: {
                    mode: 'overlay',
                },
                autostart: false,
                allowDangerousHTML: true,
                assistant: {
                    extensions: [
                        VideoExtension,
                        TimerExtension,
                        FormExtension,
                        MapExtension,
                        FileUploadExtension,
                        KBUploadExtension,
                        ConfettiExtension,
                        FeedbackExtension,
                        createDateExtension("date", "Select date"),
                        createDateExtension("time", "Select time"),
                        createDateExtension("datetime-local", "Select date/time"),
                    ],
                },
            };
            if (stylesheet) {
                assistantConfig["assistant"] = {
                    stylesheet: stylesheet
                }
            }
            window.voiceflow.chat.load(assistantConfig).then(() => {
                setTimeout(() => {
                    window.voiceflow.chat.open();
                }, 1000);
            });
        };

        const script = document.getElementsByTagName("script")[0];
        script.parentNode.insertBefore(voiceflow, script);
    }, [projectId]);
}
