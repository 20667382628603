import React from 'react';
import '../App.css';
import ChatbotPreview from "../ChatbotPreview";

const SunriseHotelBotNew = () => {
    return <ChatbotPreview chatbotName={"Sunrise Hotel"} companyLogo={"logos/sunrise_hotel_logo.png"}
                           projectID={"66b0c0f3a380c590e97e462c"}/>
};

export default SunriseHotelBotNew;
