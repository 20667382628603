import React, {useEffect} from 'react';
import './App.css';
import {ShowVoiceflowChatbot} from "./voiceflow";

const ChatbotPreview = (props) => {
    const {chatbotName, companyLogo, projectID, stylesheet} = props
    ShowVoiceflowChatbot(projectID, stylesheet);

    useEffect(() => {
        document.title = chatbotName;
        setFavicon(companyLogo)
    }, [chatbotName, companyLogo]);

    return <div>
        <img src="logos/wesolve_logo_gray.png" className="App-wesolve-logo" alt="logo"/>
        <header className="App-header">
            <img src={companyLogo} className="App-logo" alt="logo"/>
            <p className="App-text">{chatbotName}</p>
        </header>
    </div>;
};

const setFavicon = (favicon_file) => {
    const link = document.createElement("link");
    const oldLink = document.getElementById("favicon");
    link.id = "favicon";
    link.rel = "icon";
    link.href = favicon_file;

    if (oldLink) {
        document.head.removeChild(oldLink);
    }

    document.head.appendChild(link);
}

export default ChatbotPreview;
