import React from 'react';
import '../App.css';
import ChatbotPreview from "../ChatbotPreview";

const AITomBot = () => {
    return <ChatbotPreview chatbotName={"AI Tom"} companyLogo={"logos/ai_tom.png"}
                           projectID={"66310f9004113b2334085f6c"}/>
};

export default AITomBot;
