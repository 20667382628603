import React from "react";
import FacebookLoginButton from "./facebookLoginButton";
import styled from "styled-components";

function EmbeddedSignup() {
    return (<div>
        <img src="../logos/wesolve_logo_gray.png" className="App-wesolve-logo" alt="logo"/>
        <ButtonContainer>
            <Heading>Login with Facebook to link your WhatsApp number to our Platform.</Heading>
            <FacebookLoginButton/>
        </ButtonContainer>
    </div>);
}

const Heading = styled.h2`
    margin-bottom: 60px;
`

const ButtonContainer = styled.div`
  margin-top: 100px;
`

export default EmbeddedSignup;
