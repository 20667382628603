import React from 'react';
import '../App.css';
import ChatbotPreview from "../ChatbotPreview";

const SalmaDietBot = () => {
    return <ChatbotPreview chatbotName={"Diet Diversity Assistant"} companyLogo={"logos/salma_diet_bot_logo.png"}
                           projectID={"65f589da240870d76de427f1"}/>
};

export default SalmaDietBot;
