import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Mayalanya from './chatbots/mayalanya';
import './App.css';
import TomDevelopment from "./chatbots/tom_development";
import SalmaDietBot from "./chatbots/salma_diet_bot";
import SalmaDietBotAr from "./chatbots/salma_diet_bot_ar";
import RastAssistant from "./chatbots/rast_assistant";
import SalmaChildDietBot from "./chatbots/salma_child_diet_bot";
import SalmaChildDietBotAr from "./chatbots/salma_child_diet_bot_ar";
import AITomBot from "./chatbots/ai_tom_bot";
import CustomerSupportAssistant from "./chatbots/customer_support_assistant";
import EmbeddedSignup from "./embedded_signup/embeddedSignup";
import ECommerceProductRecommendationBot from "./chatbots/voiceflow_templates/ecommerce_product_recommendation_bot";
import CustomerSupportBot from "./chatbots/voiceflow_templates/customer_support_bot";
import LeadGenerationBot from "./chatbots/voiceflow_templates/lead_generation_bot";
import UpworkProposalAssistant from "./chatbots/upwork_proposal_assistant";
import PrivateSchoolBot from "./chatbots/private_school_bot";
import SunriseHotelBot from "./chatbots/sunrise_hotel_bot_interface";
import PlaygroundBot from "./chatbots/playground_bot";
import SunriseHotelBotNew from "./chatbots/sunrise_hotel_bot_new";
import FoodOrderingAssistant from "./chatbots/food_ordering_assistant";

const App = () => {
    return (<div className="App">
        <Routes>
            <Route path="/" element={<Preview/>}/>
            <Route path="/ai_tom" element={<AITomBot/>}/>
            <Route path="/tom_development" element={<TomDevelopment/>}/>
            <Route path="/playground" element={<PlaygroundBot/>}/>
            <Route path="/customer_support_assistant" element={<CustomerSupportAssistant/>}/>
            <Route path="/mayalanya" element={<Mayalanya/>}/>
            <Route path="/salma_diet_bot_en" element={<SalmaDietBot/>}/>
            <Route path="/salma_diet_bot_ar" element={<SalmaDietBotAr/>}/>
            <Route path="/salma_child_diet_bot" element={<SalmaChildDietBot/>}/>
            <Route path="/salma_child_diet_bot_ar" element={<SalmaChildDietBotAr/>}/>
            <Route path="/rast_assistant" element={<RastAssistant/>}/>
            <Route path="/embedded_signup" element={<EmbeddedSignup/>}/>
            <Route path="/ecommerce_product_recommendation" element={<ECommerceProductRecommendationBot/>}/>
            <Route path="/customer_support_bot" element={<CustomerSupportBot/>}/>
            <Route path="/lead_generation_bot" element={<LeadGenerationBot/>}/>
            <Route path="/upwork_proposal_assistant" element={<UpworkProposalAssistant/>}/>
            <Route path="/private_school_bot" element={<PrivateSchoolBot/>}/>
            <Route path="/sunrise_hotel_bot" element={<SunriseHotelBot/>}/>
            <Route path="/sunrise_hotel_bot_new" element={<SunriseHotelBotNew/>}/>
            <Route path="/food_ordering_assistant" element={<FoodOrderingAssistant/>}/>
        </Routes>
    </div>);
};

const Preview = () => {
    return (<div>
        <img src={"logos/wesolve_logo_gray.png"} className="App-wesolve-logo" alt="logo"/>
        <header className="App-header">
            <p className="App-text">Welcome to WeSolve.AI's preview page</p>
            <img src={"logos/wesolve_logo.png"} className="App-logo" alt="logo"/>
        </header>
    </div>);
}

export default App;
