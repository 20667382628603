import React from 'react';
import '../App.css';
import ChatbotPreview from "../ChatbotPreview";

const SalmaChildDietBotAr = () => {
    return <ChatbotPreview chatbotName={"اخصائية تغذية الاطفال"} companyLogo={"logos/salma_child_diet_bot_logo.png"}
                           projectID={"66229a17372ec2c9e94b4fe1"}/>
};

export default SalmaChildDietBotAr;
