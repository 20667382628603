import React from 'react';
import '../App.css';
import ChatbotPreview from "../ChatbotPreview";

const TomDevelopment = () => {
    return <ChatbotPreview chatbotName={"Tom Development Bot"} companyLogo={"logos/wesolve_dev_logo.png"}
                           projectID={"6683f413e57f855720262dda"}/>
};

export default TomDevelopment;
