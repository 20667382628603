import React from 'react';
import '../App.css';
import ChatbotPreview from "../ChatbotPreview";

const PlaygroundBot = () => {
    return <ChatbotPreview chatbotName={"Playground Bot"} companyLogo={"logos/wesolve_dev_logo.png"}
                           projectID={"6561e7ca16bf4800078f6f0c"}/>
};

export default PlaygroundBot;
